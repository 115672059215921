<template>
	   <div class="row">
            <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link   class="menu-title " to="/studentview" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                    <i class="fa fa-users" aria-hidden="true"></i>
                    </div>
                    <h5>Users</h5>
                  </div>
                  <h5></h5>
                </div>
                </router-link>
              </div>
              </div>

              <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link  class="menu-title " to="/section-one-quality-veator" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                   <i class="fa fa-files-o" aria-hidden="true"></i>
                    </div>
                    <h5>Homepage</h5>
                  </div>
                  <h5></h5>
                </div>
                </router-link>
              </div>
              </div>
              <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link  class="menu-title " to="/about" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                  <i class="fa fa-files-o" aria-hidden="true"></i>
                    </div>
                    <h5>About Page</h5>
                  </div>
                  <h5></h5>
                </div>
                </router-link>
              </div>
              </div>
               <div class="col-md-6 col-lg-4 grid-margin stretch-card">
              <div class="card cards">
                  <router-link  class="menu-title " to="/pricing" style="text-decoration: none;">
                <div class="card-body text-color" >
                  <div class="d-flex align-items-center justify-content-md-center">
                    <div   class="icon">
                    <i class="fa fa-file" aria-hidden="true"></i>
                    </div>
                    <h5>Pricing Page</h5>
                  </div>
                  <h5></h5>
                </div>
                </router-link>
              </div>
              </div>
          </div>
</template>
<script type="text/javascript">
import axios from 'axios'


export default{

 computed:{
  addminurl(){
    return this.$store.state.addminurl;

  },
  url(){
    return this.$store.state.url;
  }
  ,
  token(){
    return this.$store.state.authToken;
  },
  user(){
    return this.$store.state.user;
  },
},
data(){
  return{
   
   videocount:[], 
   pdfcount:[], 
   imagecount:[], 
   totalstudentscount:[], 
  
 }
},
 mounted(){
  this.allvideoscount();
  this.allpdfcount();
  this.allimagecount();
  this.allStudentscount();
 },

methods:{
   allvideoscount(){
      axios.get(this.addminurl+'api/auth/getvideoscount/',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.videocount=response.data;
        console.log(this.count);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },
       allStudentscount(){
      axios.get(this.addminurl+'api/auth/getstudentscount/',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.totalstudentscount=response.data;
        console.log(this.totalstudentscount);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },
       allpdfcount(){
      axios.get(this.addminurl+'api/auth/pdfcount/',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.pdfcount=response.data;
        console.log(this.pdfcount);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },

      allimagecount(){
      axios.get(this.addminurl+'api/auth/pdfcount/',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.imagecount=response.data;
        console.log(this.imagecount);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },

},
}
</script>
<style type="text/css">
  .d-flex h5{
    position: relative;
    top:15px;
  }
</style>
