<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item nav-profile">
        <div class="nav-link">
          <div class="profile-image">
            <img :src="addminurl+'profileimg/'+this.user.img"  alt="image"/>
            <span class="online-status online"></span> <!--change class online to offline or busy as needed-->
          </div>
          <div class="profile-name">
            <p class="name">
             {{this.user.name}} {{this.user.lastname}}
           </p>
           <p class="designation" v-if="this.user.usertype==1">
            Teacher
          </p>
          <p class="designation" v-else>
            Student
          </p>
        </div>
      </div>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/">
        <i class="icon-rocket menu-icon"></i>
        <span class="menu-title">Dashboard</span>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/profile">
        <i class="fa fa-user menu-icon"></i>
        <span class="menu-title">Profile</span>
      </router-link>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/Payments_create">
        <i class="fa fa-google-wallet menu-icon"></i>
        <span class="menu-title">Payments</span>
      </router-link>
    </li>
    <li class="nav-item">
      <a class="nav-link" data-toggle="collapse" href="#form-quiz" aria-expanded="false" aria-controls="form-elements">
        <i class="fa fa-check-square menu-icon"></i>
        <span class="menu-title">Quiz</span>
        <span class="badge badge-danger">2</span>
      </a>
      <div class="collapse" id="form-quiz">
        <ul class="nav flex-column sub-menu">   
          <li class="nav-item"><router-link class="nav-link" to="/mcq">Mcq</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/question">Question</router-link></li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <router-link class="nav-link" to="/result">
        <i class="icon-doc menu-icon"></i>
        <span class="menu-title">Result</span>
      </router-link>
    </li>
    <li class="nav-item nav-doc">
      <a @click="logout()" class="nav-link bg-primary" href="#">
        <i class="icon-power menu-icon"></i>
        <span class="menu-title">Logout</span>
      </a>
    </li>
  </ul>
</nav>	
</template>
<script type="text/javascript">
export default{
 name:'navView',
 computed:{

  addminurl(){
    return this.$store.state.addminurl;

  },
  url(){
    return this.$store.state.url;

  },
  user(){
    return this.$store.state.user;
  }


},
data(){
  return{

  }
},
mounted(){
 console.log(this.user);
},
methods:{
  logout(){
    this.$store.dispatch("logout"); 
  }
}
}

</script>