import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'
import router from '@/router';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import $ from 'jquery';
export default createStore({
  state: {
    addminurl:'https://admin.vectorior.com/',
    url:'http://portal.vectorior.com/',
    studentid:0,
    count: 0,
    authToken:null,
    user:[],


  },
  getters: {
     isAuthenticated: (state) => !!state.authToken,
  },
  mutations: {
    SET_TOKEN(state, data) {
    state.authToken = data.token;
    state.user = data.user;
    },
    CLEAR_USER(state) {
    state.authToken=null;
    state.user=[];
    location.reload();
   },
   UPDATE_USER(state,userData) {
    state.user=userData;
   },
  },
  actions: {
    async loginUser({ commit }, userData) {
    try {
      const response = await axios.post(this.state.addminurl+'api/auth/login', userData);
      if(response.data=='error'){
        toast("The provided credentials are incorrect", {
          autoClose: 5000,
        });
        $('#exampleInputEmail1').css('border-color','red');
        $('#exampleInputEmail1').css('color','red');
        $('#exampleInputPassword1').css('border-color','red');
        $('#exampleInputPassword1').css('color','red');
        
      }else{
       commit('SET_TOKEN', response.data);
       router.push({ name: 'Dashboard' });
       location.reload();
      }
    } catch (error) {
      console.error("There was an error logging in", error);
    }
    },
    updateuser({ commit }, userData) {
      commit('UPDATE_USER', userData);
    },
    async logout({ commit }) {
    try {
      const response = await axios.post(this.state.addminurl+'api/auth/logout');
      commit("CLEAR_USER");
      console.log(response);
      router.push({ name: 'login' });
    } catch (error) {
      commit("CLEAR_USER");
    }
   }
  },
  modules: {
  },
  plugins: [new VuexPersistence().plugin]
})
