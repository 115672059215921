<template>
  <div class="vld-parent">
    <loading :active="isLoading" 
    :can-cancel="true" 
    :on-cancel="onCancel"
    :is-full-page="fullPage"></loading>
  </div>
  <i class="settings-close mdi mdi-close"></i>
  <ul class="nav nav-tabs" id="setting-panel" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" id="todo-tab" data-toggle="tab" href="#todo-section" role="tab" aria-controls="todo-section" aria-expanded="true">TO DO LIST</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="chats-tab" data-toggle="tab" href="#chats-section" role="tab" aria-controls="chats-section">All Students</a>
    </li>
  </ul>
  <div class="tab-content" id="setting-content">
    <div class="tab-pane fade show active scroll-wrapper" id="todo-section" role="tabpanel" aria-labelledby="todo-section">
      <div class="add-items d-flex px-3 mb-0">
        <form @submit.prevent="todolistsss" class="form w-100">
          <div class="form-group d-flex">
            <input class="form-control todo-list-input"  type="text" v-model="todolist" placeholder="Add To-do">
            <button class="add btn btn-primary" type="submit" value="Submit" >Add</button>
          </div>
        </form>
      </div>
      <div class="list-wrapper px-3">
        <ul class="d-flex flex-column-reverse todo-list" v-for="todolistview in this.todolistviews">
          <li>
            <div class="form-check">
              <label class="form-check-label">
                <input class="checkbox" type="checkbox">
                {{todolistview.task}}
              </label>
            </div>
            <i class="remove mdi mdi-close-circle-outline"></i>
          </li>
        </ul>
      </div>
    </div>
    <!-- To do section tab ends -->
    <div class="tab-pane fade" id="chats-section" role="tabpanel" aria-labelledby="chats-section">
      <div class="d-flex align-items-center justify-content-between border-bottom">
        <p class="settings-heading border-top-0 mb-3 pl-3 pt-0 border-bottom-0 pb-0">Students</p>
        <small class="settings-heading border-top-0 mb-3 pt-0 border-bottom-0 pb-0 pr-3 font-weight-normal">See All</small>
      </div>
      <ul class="chat-list" v-for="studentlists in this.studentlist">
        <li class="list active">
          <div class="profile"><img :src="addminurl+'profileimg/'+studentlists.img"><span class="online"></span></div>
          <div class="info">
            <p>{{studentlists.name}} {{studentlists.lastname}}</p>
            <p>{{studentlists.phone}}</p>
          </div>
          <small class="text-muted my-auto"> {{moment(studentlists.created_at).fromNow()}}</small>
        </li>
      </ul>
    </div>
  </div>

</template>
<script type="text/javascript">
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from 'axios'
import  Loading  from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import moment from 'moment';


export default{
  components: {
    Loading
  },

  computed:{
    addminurl(){
      return this.$store.state.addminurl;

    },
    url(){
      return this.$store.state.url;
    }
    ,
    token(){
      return this.$store.state.authToken;
    },
    user(){
      return this.$store.state.user;
    },
  },
  data(){
    return{
      isLoading:false,
      fullPage:true,
      studentlist:[], 
      todolistviews:[], 
      moment,
      todolist:'',


    }
  },
  mounted(){
    this.studentlistview();
    this.todolistview();
  },

  methods:{
   studentlistview(){
    axios.get(this.addminurl+'api/auth/getstudentslist/',{
      headers: {
        Authorization:`Bearer ${this.token}`
      }
    })
    .then(response => {
      this.studentlist=response.data;
      console.log(this.studentlist);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  },
  todolistsss(){
    this.isLoading=true;
    axios.post(this.addminurl+'api/auth/ToDoListss',{
      classid:this.user.id,
      todolists:this.todolist,

    },{
      headers: {
        Authorization: `Bearer ${this.token}`
      }}).then(response=>{
        console.log(response.data);
        this.isLoading=false;
        this.todolist="";
        toast("subject create suceessfully", {
         autoClose: 1000,
       });
      });

    },
    todolistview(){
      axios.get(this.addminurl+'api/auth/gettodolis/',{
        headers: {
          Authorization:`Bearer ${this.token}`
        }
      })
      .then(response => {
        this.todolistviews=response.data;
       this.todolistview();
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    },

  },
}
</script>
