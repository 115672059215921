<template>
        <nav class="sidebar sidebar-offcanvas" id="sidebar">
          <ul class="nav">
            <li class="nav-item nav-profile">
              <div class="nav-link">
                <div class="profile-image">
                  <img :src="addminurl+'profileimg/'+this.user.img"  alt="image"/>
                  <span class="online-status online"></span> <!--change class online to offline or busy as needed-->
                </div>
                <div class="profile-name">
                  <p class="name">
                   {{this.user.name}} {{this.user.lastname}}
                  </p>
                  <p class="designation" v-if="this.user.usertype==1">
                    Admin
                  </p>
                  <p class="designation" v-else>
                    User
                  </p>
                </div>
              </div>
            </li>

            <!--Dashboard -->
            <li class="nav-item">
              <router-link class="nav-link" to="/">
                <i class="icon-rocket menu-icon"></i>
                   <span class="menu-title">Dashboard</span>
              </router-link>
            </li>

            <!-- profile-->
            <li class="nav-item">
              <router-link class="nav-link" to="/profile">
                <i class="fa fa-user menu-icon"></i>
                 <span class="menu-title">Profile</span>
              </router-link>
            </li>

            <!--registarion-->
            <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" href="#page-layouts" aria-expanded="false" aria-controls="page-layouts">
                <i class="fa fa-users menu-icon" ></i>
                <span class="menu-title">Users</span>
                <span class="badge badge-danger"></span>
              </a>
              <div class="collapse" id="page-layouts">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item"><router-link class="nav-link" to="/student-registration">Create User</router-link></li>
                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/studentview">Users</router-link></li>
                </ul>
              </div>
            </li>

              <!-- Home  page -->
             <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" href="#page-homepage" aria-expanded="false" aria-controls="page-homepage">
                <i class="fa fa-files-o menu-icon" ></i>
                <span class="menu-title">Home Page</span>
                <span class="badge badge-danger"></span>
              </a>
              <div class="collapse" id="page-homepage">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item"><router-link class="nav-link" to="/section-one-quality-veator">Quality Vector</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/section-two-conversion">Our Vector Conversion</router-link></li>

                  <li class="nav-item"><router-link class="nav-link" to="/section-three-Best-vector">Get Best Vector Conversion</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/section_four-with-us"> Work With Us</router-link></li>
                  <li class="nav-item">
                        <a class="nav-link" data-toggle="collapse" href="#user-layouts" aria-expanded="false" aria-controls="user-layouts">
                          <span class="menu-title">How We Deliver</span>
                          <span class="badge badge-danger">3</span>
                        </a>
                        <div class="collapse" id="user-layouts">
                          <ul class="nav flex-column sub-menu mysubnav">
                            <li class="nav-item"><router-link class="nav-link" to="/section_six-Deliver-Services-order-and-Send">order and Send</router-link></li>
                              <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/section_six-Deliver-Services-Converting">  Converting Vector</router-link></li>
                              <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/section_six-Deliver-Services-Print">Print and Deliver</router-link></li>
                          </ul>
                        </div>
                    </li>
                     <li class="nav-item"><router-link class="nav-link" to="/section_seven-portfolio">Print and Deliver</router-link></li>
                     <li class="nav-item"><router-link class="nav-link" to="/section_eight_Customer">Testimonial</router-link></li>
                </ul>
              </div>
            </li>

            <!--- about page --->
              <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" href="#page-aboutpage" aria-expanded="false" aria-controls="page-aboutpage">
                <i class="fa fa-files-o menu-icon" ></i>
                <span class="menu-title">About Page </span>
                <span class="badge badge-danger"></span>
              </a>
              <div class="collapse" id="page-aboutpage">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item"><router-link class="nav-link" to="/about">ABOUT US</router-link></li>
                   <li class="nav-item">
                        <a class="nav-link" data-toggle="collapse" href="#user-layouts" aria-expanded="false" aria-controls="user-layouts">
                          <span class="menu-title">OUR STORY</span>
                          <span class="badge badge-danger">2</span>
                        </a>
                        <div class="collapse" id="user-layouts">
                          <ul class="nav flex-column sub-menu mysubnav">
                            <li class="nav-item"><router-link class="nav-link" to="/our-story-section-one">OUR STORY One</router-link></li>
                              <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/our-story-section-two">OUR STORY Two</router-link></li>
                          </ul>
                        </div>
                    </li>
                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Team-membar">Meet The Team</router-link></li>
                </ul>
              </div>
            </li>


            <!---pricing --->
            <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" href="#page-pricing" aria-expanded="false" aria-controls="page-pricing">
                <i class="fa fa-files-o menu-icon" ></i>
                <span class="menu-title">Pricing Page</span>
                <span class="badge badge-danger"></span>
              </a>
              <div class="collapse" id="page-pricing">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item"><router-link class="nav-link" to="/pricng-section-one-Pay-only">Pay only</router-link></li>
                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/pricng-section-Engage">Engage your graphic</router-link></li>
                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/pricng-section-order-more">Order More to Save</router-link></li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="collapse" href="#class-contactpage" aria-expanded="false" aria-controls="class-contactpage">
                        <span class="menu-title">Oure packages</span>
                        <span class="badge badge-danger">3</span>
                      </a>
                      <div class="collapse" id="class-contactpage">
                        <ul class="nav flex-column sub-menu">
                           <li class="nav-item"><router-link class="nav-link" to="/pricingtype">pricingtype  </router-link></li>
                          <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/plan">plan </router-link></li>
                          <li class="nav-item"><router-link class="nav-link" to="/pricing">pricing  </router-link></li>
                        </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>


            <!--- service logo to vector -->
            <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="#page-servicepageone" aria-expanded="false" aria-controls="page-servicepageone">
                  <i class="fa fa-files-o menu-icon" ></i>
                  <span class="menu-title">Logo to vector conversion</span>
                  <span class="badge badge-danger"></span>
                </a>
                <div class="collapse" id="page-servicepageone">
                    <ul class="nav flex-column sub-menu">
                  <li class="nav-item">
                  <a class="nav-link" data-toggle="collapse" href="#class-contactpage" aria-expanded="false" aria-controls="class-contactpage">
                    <span class="menu-title">Logo Vectorization</span>
                    <span class="badge badge-danger">2</span>
                  </a>
                  <div class="collapse" id="class-contactpage">
                    <ul class="nav flex-column sub-menu">
                       <li class="nav-item"><router-link class="nav-link" to="/Logo-Vectorization">logo vectorization</router-link></li>
                      <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Logo-Vectorization-feature">Feature </router-link></li>
                    </ul>
                  </div>
                </li>
                <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Vectorizing-Logo">Vectorizing Logo</router-link></li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="collapse" href="#page-logooure" aria-expanded="false" aria-controls="page-logooure">
                    <span class="menu-title">Our Logo Vectorization</span>
                    <span class="badge badge-danger">4</span>
                  </a>
                  <div class="collapse" id="page-logooure">
                    <ul class="nav flex-column sub-menu">

                      <li class="nav-item"><router-link class="nav-link" to="/yourlogodesctiption"> Our Logo descripion</router-link></li>

                    <li class="nav-item"><router-link class="nav-link" to="/Embroidery">Embroidery Logo </router-link></li>

                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Cnc-Design">Engraving/ CNC Design</router-link></li>

                    <li class="nav-item"><router-link class="nav-link" to="/Printing">Printing for Vector</router-link></li>
                    </ul>
                  </div>
                </li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Convert">Convert A Logo </router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Importance">Importance Descripton </router-link></li>

                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Importance-box">Importance Box</router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Importance-images">Importance images</router-link></li>
                  <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Vector-file ">Vector File </router-link></li>
                  </ul>
                </div>
            </li>

            <!--service Vector Illustration -->
            <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" href="#page-footers" aria-expanded="false" aria-controls="page-footers">
                <i class="fa fa-files-o menu-icon" ></i>
                <span class="menu-title">Vector Ilustraion & Artwork</span>
                <span class="badge badge-danger"></span>
              </a>
              <div class="collapse" id="page-footers">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item"><router-link class="nav-link" to="/Vector-Artwork-feature">Vector Artwork feature</router-link></li>
                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/Vector-Illustration">Vector Illustration</router-link></li>
                </ul>
              </div>
            </li>

            <!---blog--->
            <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" href="#page-blogpage" aria-expanded="false" aria-controls="page-blogpage">
                <i class="fa fa-files-o menu-icon" ></i>
                <span class="menu-title">Blog page</span>
                <span class="badge badge-danger"></span>
              </a>
              <div class="collapse" id="page-blogpage">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item"><router-link class="nav-link" to="/blog">blog create</router-link></li>
                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/blodescription">Blog Desctipion</router-link></li>
                </ul>
              </div>
            </li>

              <!---footer imgag --->
            <li class="nav-item">
              <a class="nav-link" data-toggle="collapse" href="#page-footers" aria-expanded="false" aria-controls="page-footers">
                <i class="fa fa-files-o menu-icon" ></i>
                <span class="menu-title">Footer Images</span>
                <span class="badge badge-danger"></span>
              </a>
              <div class="collapse" id="page-footers">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item"><router-link class="nav-link" to="/Home-footer-img">Home footer img</router-link></li>
                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/about-footer-img">About footer img</router-link></li>
                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/pricing-footer-img">Pricing footer img</router-link></li>
                    <li class="nav-item d-none d-lg-block"> <router-link class="nav-link" to="/service-footer-img">Service footer img</router-link></li>
                </ul>
              </div>
            </li>

            <!---basic setting --->
             <li class="nav-item">
              <router-link class="nav-link" to="/basic_settings">
              <i class="fa fa-cogs menu-icon"></i>
                <span class="menu-title">Basic Settings </span>
              </router-link>
            </li>

            <li class="nav-item nav-doc">
              <a @click="logout()" class="nav-link bg-primary" href="#">
                <i class="icon-power menu-icon"></i>
                <span class="menu-title">Logout</span>
              </a>
            </li>
          </ul>
       </nav>	
</template>
<script type="text/javascript">
    export default{
  	name:'navView',
    computed:{

      addminurl(){
        return this.$store.state.addminurl;

      },
      url(){
        return this.$store.state.url;

      },
      user(){
        return this.$store.state.user;
      }


    },
    data(){
      return{

      }
    },
    mounted(){
     console.log(this.user);
    },
    methods:{
      logout(){
        this.$store.dispatch("logout"); 
      }
    }
   }

</script>