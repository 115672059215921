import { createRouter, createWebHistory } from 'vue-router'
import Dashboardview from '../views/Dashboard/DashboardView.vue'
// import Loginview from '../views/Login-View.vue'
import store from '../store'
const routes = [
 {
    path: '/login',
    name: 'login',
    meta: { layout: 'empty' }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboardview
  },
  {
    path: '/profile',
    name: 'profileview',
    component: () => import('../views/profile/profileView.vue')
  },


  {
    path: '/section-one-quality-veator',
    name: 'section_oneview',
    component: () => import('../views/Homepage/section_one/create.vue')
  },


  {
    path: '/section-two-conversion',
    name: 'conversionview',
    component: () => import('../views/Homepage/section_two/create.vue')
  },


{
    path: '/section-three-Best-vector',
    name: 'Best-vectorview',
    component: () => import('../views/Homepage/section_three/create.vue')
  },


    {
    path: '/section_four-with-us',
    name: 'withusview',
    component: () => import('../views/Homepage/section_four/createView.vue')
  },


   

   {
    path: '/section_six-Deliver-Services-order-and-Send',
    name: 'Deliverorder',
    component: () => import('../views/Homepage/section_six/sectionone/create.vue')
  },


 {
    path: '/section_six-Deliver-Services-Converting',
    name: 'Deliverconverting',
    component: () => import('../views/Homepage/section_six/sectiontwo/create.vue')
  },

{
    path: '/section_six-Deliver-Services-Print',
    name: 'DeliverPrint',
    component: () => import('../views/Homepage/section_six/sectionthree/create.vue')
  },


  {
    path: '/section_seven-portfolio',
    name: 'portfolioview',
    component: () => import('../views/Homepage/section_seven/create.vue')
  },


  {
    path: '/section_eight_Customer',
    name: 'customerview',
    component: () => import('../views/Homepage/section_eight/create.vue')
  },


{
    path: '/about',
    name: 'aboutpage',
    component: () => import('../views/Aboutpage/section_one/create.vue')
  },




{
    path: '/our-story-section-one',
    name: 'ourstoryview',
    component: () => import('../views/Aboutpage/section_two/sectionone/create.vue')
  },

  {
    path: '/our-story-section-two',
    name: 'ourstoryviews',
    component: () => import('../views/Aboutpage/section_two/sectiontwo/create.vue')
  },

{
    path: '/Team-membar',
    name: 'Teammembarview',
    component: () => import('../views/Aboutpage/section_three/create.vue')
  },



{
    path: '/pricng-section-one-Pay-only',
    name: 'payonlipricnviews',
    component: () => import('../views/Pricingpage/section_one/create.vue')
  },



{
    path: '/pricng-section-Engage',
    name: 'payonlipricnview',
    component: () => import('../views/Pricingpage/section_two/create.vue')
  },


{
    path: '/pricng-section-order-more',
    name: 'ordermoreview',
    component: () => import('../views/Pricingpage/section_three/create.vue')
  },





  {
    path: '/blog',
    name: 'blogview',
    component: () => import('../views/Blogpage/create.vue')
  },


  {
    path: '/blodescription',
    name: 'blodescriptionview',
    component: () => import('../views/Blogpage/blogdescription.vue')
  },


  
    {
    path: '/plan',
    name: 'planview',
    component: () => import('../views/Pricingpage/packages/planCreate.vue')
  },

      {
    path: '/basic_settings',
    name: 'basic_settingsvie',
    component: () => import('../views/Basic_settings/create.vue')
  },

   {
    path: '/pricingtype',
    name: 'pricingtypeview',
    component: () => import('../views/Pricingpage/packages/pricingtypeCreate.vue')
  },
  {
    path: '/pricing',
    name: 'pricingview',
    component: () => import('../views/Pricingpage/packages/pricingCreate.vue')
  },
  
  
  {
    path: '/Logo-Vectorization',
    name: 'logovectorizationview',
    component: () => import('../views/Servicepage_one/section_one/create.vue')
  },


 {
    path: '/Logo-Vectorization-feature',
    name: 'logovectorizationfeaturevies',
    component: () => import('../views/Servicepage_one/section_one/featureCreate.vue')
  },


  {
    path: '/Vectorizing-Logo',
    name: 'VectorizingLogoview',
    component: () => import('../views/Servicepage_one/section_two/create.vue')
  },


  {
    path: '/yourlogodesctiption',
    name: 'yourlogodesctiptionview',
    component: () => import('../views/Servicepage_one/section_three/description.vue')
  },



 {
    path: '/Embroidery',
    name: 'Embroideryview',
    component: () => import('../views/Servicepage_one/section_three/section_one/create.vue')
  },

  

 {
    path: '/Cnc-Design',
    name: 'CncDesignview',
    component: () => import('../views/Servicepage_one/section_three/section_two/create.vue')
  },
  


 {
    path: '/Printing',
    name: 'Printingview',
    component: () => import('../views/Servicepage_one/section_three/section_three/create.vue')
  },
  

 {
    path: '/Convert',
    name: 'Convertview',
    component: () => import('../views/Servicepage_one/section_four/create.vue')
  },



{
    path: '/Importance',
    name: 'Importanceview',
    component: () => import('../views/Servicepage_one/section_five/description.vue')
  },

{
    path: '/Importance-box',
    name: 'Importanceboxview',
    component: () => import('../views/Servicepage_one/section_five/section_one/create.vue')
  },

{
    path: '/Importance-images',
    name: 'Importanceimagesview',
    component: () => import('../views/Servicepage_one/section_five/section_two/create.vue')
  },

{
    path: '/Vector-file',
    name: 'Vectorfileview',
    component: () => import('../views/Servicepage_one/section_six/create.vue')
  },




{
    path: '/Home-footer-img',
    name: 'Homefooterimgview',
    component: () => import('../views/Footerimg/homefooterimg/create.vue')
  },

{
    path: '/about-footer-img',
    name: 'aboutfooterimgview',
    component: () => import('../views/Footerimg/aboutfooterimg/create.vue')
  },


{
    path: '/pricing-footer-img',
    name: 'pricingfooterimgview',
    component: () => import('../views/Footerimg/pricingfooterimg/create.vue')
  },


{
    path: '/service-footer-img',
    name: 'servicefooterimgview',
    component: () => import('../views/Footerimg/servicefooterimg/create.vue')
  },




{
    path: '/Vector-Artwork-feature',
    name: 'VectorArtworkfeatureview',
    component: () => import('../views/servicepage_two/section_one/create.vue')
  },




{
    path: '/Vector-Illustration',
    name: 'VectorIllustrationview',
    component: () => import('../views/servicepage_two/section_two/create.vue')
  },


























  {
    path: '/studentvedio',
    name: 'studenvedios',
    component: () => import( '../views/Dashboard/studentvideo.vue')
  },

   {
    path: '/pdf_list',
    name: 'pdf_lists',
    component: () => import('../views/Dashboard/student_pdf.vue')
  },
   
   {
    path: '/PDFfilelist',
    name: 'pdflist',
    component: () => import( '../views/Dashboard/pdf-list.vue')
  },
   {
    path: '/imagelist',
    name: 'imagelists',
    component: () => import('../views/Dashboard/image-list.vue')
  },
   {
    path: '/studentview',
    name: 'studentview',
    component: () => import('../views/student/studentView.vue')
  },
   {
    path: '/student-registration',
    name: 'studentviewregistion',
    component: () => import('../views/student/registration-student.vue')
  },
  {
    path: '/studetneditdata/:id',
    name: 'updatestudent',
    props:true,
    component: () => import('../views/student/edit_Student.vue')
  },



  {
    path: '/registation',
    name: 'registationview',
    props:true,
    component: () => import('../views/Registration/create.vue')
  },




  


  
   
 
  

  
 
  
 
 

 

  

  
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters.isAuthenticated && to.name === 'login') {
    next({ path: '/' }); 
  }
  else if(!store.getters.isAuthenticated && to.name !=='login') {
    next({ name: 'login' });
  }
  else {
    next(); 
  }
});

export default router








