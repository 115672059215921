<template>
   <div class="container-scroller"> 
    <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" v-if="this.user.usertype==1">
         <Teacherheader></Teacherheader>
      </nav>
       <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" v-else>
         <StudentHeader></StudentHeader>
       </nav>
    <div class="container-fluid page-body-wrapper">
      <div class="row row-offcanvas row-offcanvas-right">
        <!-- partial:partials/_settings-panel.html -->
        <div class="theme-setting-wrapper">
          <div id="settings-trigger"><i class="mdi mdi-settings"></i></div>
          <div id="theme-settings" class="settings-panel">
            <i class="settings-close mdi mdi-close"></i>
            <p class="settings-heading">SIDEBAR SKINS</p>
            <div class="sidebar-bg-options selected" id="sidebar-light-theme"><div class="img-ss rounded-circle bg-light border mr-3"></div>Light</div>
            <div class="sidebar-bg-options" id="sidebar-dark-theme"><div class="img-ss rounded-circle bg-dark border mr-3"></div>Dark</div>
            <p class="settings-heading mt-2">HEADER SKINS</p>
            <div class="color-tiles mx-0 px-4">
              <div class="tiles primary"></div>
              <div class="tiles success"></div>
              <div class="tiles warning"></div>
              <div class="tiles danger"></div>
              <div class="tiles pink"></div>
              <div class="tiles info"></div>
              <div class="tiles dark"></div>
              <div class="tiles default"></div>
            </div>
          </div>
        </div>
        <div id="right-sidebar" class="settings-panel">

         <rightsideView></rightsideView>

        </div>
      </div>
     </div>
          
    <!-- partial -->
    <div class="container-fluid page-body-wrapper">
      <div class="row row-offcanvas row-offcanvas-right">
        <!-- partial:partials/_settings-panel.html -->
        <div class="theme-setting-wrapper">
          <div id="settings-trigger"><i class="mdi mdi-settings"></i></div>
          <div id="theme-settings" class="settings-panel">
            <i class="settings-close mdi mdi-close"></i>
            <p class="settings-heading">SIDEBAR SKINS</p>
            <div class="sidebar-bg-options selected" id="sidebar-light-theme"><div class="img-ss rounded-circle bg-light border mr-3"></div>Light</div>
            <div class="sidebar-bg-options" id="sidebar-dark-theme"><div class="img-ss rounded-circle bg-dark border mr-3"></div>Dark</div>
            <p class="settings-heading mt-2">HEADER SKINS</p>
            <div class="color-tiles mx-0 px-4">
              <div class="tiles primary"></div>
              <div class="tiles success"></div>
              <div class="tiles warning"></div>
              <div class="tiles danger"></div>
              <div class="tiles pink"></div>
              <div class="tiles info"></div>
              <div class="tiles dark"></div>
              <div class="tiles default"></div>
            </div>
          </div>
        </div>
       <div v-if="this.user.usertype==1">
         <navView></navView>
       </div>
       <div v-else>
         <StudentNavbar></StudentNavbar>
       </div>

         <transition name="slide" mode="out-in">
           <router-view />
           
         </transition>
         <footerView></footerView>

    </div>
  </div>
</div>
</template>
<script>
  import Teacherheader from '../partials/Teacherheader.vue';
  import StudentHeader from '../partials/StudentHeader.vue';
  import navView from '../partials/navView.vue';
  import StudentNavbar from '../partials/StudentNavbar.vue';
  import rightsideView from '../partials/rightsideView.vue';
  import footerView from '../partials/footerView.vue';

    export default{
    computed:{
      addminurl(){
        return this.$store.state.addminurl;

      },
      url(){
        return this.$store.state.url;
      },
      token(){
      return this.$store.state.authToken;
      },
      user(){
      return this.$store.state.user;
      }
    },
    components: {
    Teacherheader,
    StudentHeader,
     navView,
     rightsideView,
     footerView,
     StudentNavbar
    },
    mounted(){
      console.log(this.user);
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<style>
.slide-enter-from{
  opacity: 0;
  transform: translateX(100px);
}
.slide-enter-active {
  transition: all .9s ease-out;
}
.slide-leave-to{
  opacity: 0;
  transform: translateX(-100px);
}
.slide-leave-active {
  transition: all .7s ease-in;
}
</style>