<template>
      <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a class="navbar-brand brand-logo" href="index.html"><img src="images/logo.svg" alt="logo"/></a>
        <a class="navbar-brand brand-logo-mini" href="index.html"><img src="images/logo-mini.svg" alt="logo"/></a> 
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-center">
        <button @click="minimizenavbar()" class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span class="icon-menu"></span>
        </button>
        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span class="icon-menu"></span>
        </button>
      </div>
</template>
<script>
	export default{
	name:'Teacherheader',
  methods:{
   minimizenavbar(){
    if (document.body.classList.contains('sidebar-icon-only')) {
      document.body.classList.remove('sidebar-icon-only');
     } else {
      document.body.classList.add('sidebar-icon-only');
     }
   }
  }
	}
</script>